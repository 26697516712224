.modal {
  display: block;
  z-index: 1000 !important;
}

.show {
  display: block;
}

.hide {
  display: none;
}

.ReactModal__Overlay {
  z-index: 10000;
}

.modal.show .modal-dialog {
  transform: translate(0, 0);
  transform: translate(0, 0);
  min-height: calc(100vh - 60px);
  display: flex;
  flex-direction: unset !important;
  justify-content: center;
  width: 100% !important;
}

.modal-header {
  padding-top: 10px !important;
}

.modal-overlay {
  position: fixed;
  z-index: 1;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
}
.modal-body a {
  position: absolute;
  display: inline;
  color: #222;
  text-decoration: none;
  line-height: 36px;
  font-size: 30px;
  font-weight: lighter;
  background: #fff;
  border-radius: 5px;
  text-align: center;
}
.modal-body .modal-close {
  right: 0;
  top: 0;
  border-radius: 0 0 0 5px;
}
.modal-body {
  position: relative;
}

.clsTimetickerspan {
  font-size: 20px;
  margin: 10px;
  color: green;
  font-weight: bold !important;
}

.dangertimetickerblinking {
  animation: blinkingText 0.5s infinite;
  font-size: 20px;
  margin: 10px;
  font-weight: bold !important;
}
@keyframes blinkingText {
  0% {
    color: red;
  }
  49% {
    color: red;
  }
  60% {
    color: transparent;
  }
  99% {
    color: transparent;
  }
  100% {
    color: red;
  }
}
