.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/*video chats*/

html,
body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

.App-header {
    background-color: #222;
    height: 40px;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
}

.App-header h1 {
    font-size: 16px;
    font-weight: 200;
}

.App-logo {
    height: 60%;
    width: auto;
}

.App-main {
    position: relative;
    width: 75vw;
    height: calc(75vw * 0.6);
    margin: 10px auto;
    border: 1px solid lightblue;
}

.App-control-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.App-control-container.hidden {
    display: none;
}

.App-control-container .ots-video-control {
    width: 50px;
    height: 50px;
    margin: 20px 0 !important;
    border: 2px solid white;
    border-radius: 50%;
    background-position: center;
    background-color: rgba(27, 134, 144, 0.4);
    background-repeat: no-repeat;
    cursor: pointer;
}

.App-control-container .ots-video-control.audio {
    background-image: url(https://assets.tokbox.com/solutions/images/icon-mic.png);
}

.App-control-container .ots-video-control.audio:hover,
.App-control-container .ots-video-control.audio.muted {
    background-image: url(https://assets.tokbox.com/solutions/images/icon-muted-mic.png);
}

.App-control-container .ots-video-control.video {
    background-image: url(https://assets.tokbox.com/solutions/images/icon-video.png);
}

.App-control-container .ots-video-control.video.muted {
    background-image: url(https://assets.tokbox.com/solutions/images/icon-no-video.png);
}

.App-control-container .ots-video-control.end-call {
    background-image: url(https://assets.tokbox.com/solutions/images/icon-hang-up.png);
    background-color: red;
}

.App-video-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.App-mask {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.App-mask .react-spinner {
    position: absolute;
}

.App-mask .message {
    font-weight: 200;
}

.App-mask .message.with-spinner {
    position: absolute;
    top: 55%;
}

.App-mask .message.button {
    border: 1px solid white;
    padding: 20px 40px;
    border-radius: 6px;
    background-color: Transparent;
    color: white;
    font-family: sans-serif;
    font-size: medium;
}

.App-mask .message.button:focus:active,
.message.button:focus,
.message.button:active {
    background-image: none;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.App-video-container .video-container {
    width: 100%;
    height: 100%;
    display: flex;
}

.App-video-container .video-container.small {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 160px;
    height: 96px;
    border: 1px solid #fcba00;
    z-index: 2;
}

.App-video-container .video-container.small.left {
    left: 20px;
    border: 1px solid #00fcc2;
}

.App-video-container .video-container.hidden {
    display: none;
}

.App-video-container .video-container.active-gt2 {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 100%;
    width: 100%;
}

.App-video-container .video-container.active-gt2.hidden {
    display: none;
}

.App-video-container .video-container.active-gt2 .OT_subscriber {
    margin: 0;
    padding: 0;
    display: inline-block;
    font-size: 0;
    flex-basis: 50%;
    box-sizing: border-box;
    width: auto !important;
    height: auto !important;
}

.App-video-container .video-container.active-gt2.active-odd .OT_subscriber:first-child {
    flex-basis: 100%;
}

.ots-text-chat {
    margin-top: 0px !important;
}
.ots-text-chat .ots-messages-holder {
    /* height: 350px !important; */
}

.OTSubscriberContainer {
    top: 20px;
    position: absolute;
    left: 20px;
    width: 160px;
    height: 96px;
    z-index: 2;
}

.my-patient-tab .nav-pills .nav-link {
    cursor: pointer;
}

.wallet-balance {
    border-radius: 6px;
    position: absolute;
    right: 10px;
    top: 15px;
    padding: 1px 8px;
}
