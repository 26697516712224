
@media (min-width: 1500px){
     .home-slider .owl-item img {
    height: 800px;
    }
    .container {
        max-width: 1440px !important;
    }
    .small-container {
        max-width: 750px !important;
    }
    /* .col-md-6.heading-column {
        -ms-flex: 0 0 30%;
        flex: 0 0 30%;
        max-width: 30%;
    } */
    .col-md-6.heading-column {
        -ms-flex: 0 0 26%;
        flex: 0 0 26%;
        max-width: 26%;
    }
    .col-md-6.heading-column p{
        font-size: 16px;
        word-break: break-all;
    }
    /* .col-md-6.decription-column {
        -ms-flex: 0 0 70%;
        flex: 0 0 80%;
        max-width: 80%;
    } */
    .col-md-6.decription-column {
        -ms-flex: 0 0 74%;
        flex: 0 0 74%;
        max-width: 74%;
    }
    .suggestion-band .col-md-6.heading-column{
        -ms-flex: 0 0 10%;
        flex: 0 0 10%;
        max-width: 10%;
    }
    .suggestion-band .col-md-6.decription-column{
        -ms-flex: 0 0 80%;
        flex: 0 0 80%;
        max-width: 80%;
    }
    .button-group-doc {
        -webkit-column-count: 9;
        -moz-column-count: 9;
        column-count: 9;
        -webkit-column-gap: 0;
        -moz-column-gap: 0;
        column-gap: 0;
    }
}
/* divya */
@media all and (max-width: 991px) {
    .profile-header-block .float-right{
        float: right !important;
    }
    .inner-section-content label.control-label i:before{
            font-size: 20px !important;
    }
    /* .fixed-top{
        position: relative;
    } */
}
/* ================================= Common For All mobile definitions #mx ================================= */

@media all and (max-width: 767px) {
    /* .breadcrumbd-content{display:none;} */
    /* header changes */
    .video-chat-form{
        bottom: inherit !important;
    }
    .homeInnerSection .js-select{
        padding-bottom: 0;
        line-height: normal !important;
    }
    .homeInnerSection .js-select .css-1wa3eu0-placeholder, .homeInnerSection .js-select .selectCustomClass__placeholder{
        bottom: 0 !important;
    }
    .homeInnerSection .css-b8ldur-Input, .homeInnerSection .selectCustomClass__input {
        margin-left: 4%;
        opacity: 1;
        text-align: left;
    }
    .homepage-section .inner-section-content label.control-label i:before {
        bottom: -2px;
    }
    .homepage-section .homeInnerSection .js-select .css-1wa3eu0-placeholder, .homepage-section .homeInnerSection .js-select .selectCustomClass__placeholder{
        padding-left: 32px;
        text-align: left;
    }
    .homepage-section .css-b8ldur-Input{
        margin: 8px;
    }
    .doc-header ul.center-menu li a::before {
        bottom: 0;
        height: 9px !important;
    }
    .careear-form .btn-group .doc-btn i, .doc-btn i {
        top: 8px !important;
        right: 8px !important;
        height: 30px !important;
        width: 30px !important;
    }
    .text-right .careear-form .btn-group .doc-btn i{
        right: auto !important;
        left:6px !important;
    }
    
    ul.navbar-nav.notification {
        padding: 15px 0px 0px;
    }
    ul.navbar-nav.notification li{
        margin-top:0px !important;
    }
     /* header changes */
    .custom-datepicker:before{
        top: 0px;
    }
    .patient_profileform-section .text-left .custom-datepicker::before{
        right: -5px;
    }
    .text-right .patient_profile_form .custom-datepicker:before{
        left: -5px;
    }
    .addDisesesBtn{
        margin: 0px !important;
        padding-bottom: 18px !important;
    }
    .listOfDiseses{
        margin-bottom: 0px !important;
        padding-bottom: 0px !important;
    }
    .text-right section.welcome-band .doc-btn {
        /* padding: 13px 17px 13px 70px !important; */
        padding: 16px 25px 16px 60px !important;
        width: fit-content;
        width: -moz-fit-content;
    }
    .navbar-toggler img{
        width:45% !important;
        float:none !important;
    }
    .bannerText {
        display: none !important;
    }
    label {
        font-size: 14px;
    }
    ul.navbar-nav.language.m-3 li.nav-item {
        margin-top: 0px !important;
    }
    .main-menuright.d-flex.align-items-center {
        padding-top: 0px;
    }
    .services-band .btn-text{
        bottom: 40px;
    }
    ul.navbar-nav.language.m-3 {
        margin-top: 0px !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
    .blog-inner-content .timing-content.text-md-left.text-right p {
        text-align: left !important;
    }
    .text-right .social-icon-group.text-right, .text-right .blog-inner-content .timing-content.text-md-left.text-right p {
        text-align: right !important;
    }
    .video-react {
        padding: 65px !important;
    }
    .video-band {
        width: 100%;
        height: 150px;
    }
    .hiw-banner .nav-pills li a{
        padding: 9px 15px;
        font-size: 12px !important;
    }
    .radio-container input{
        left: 0;
    }
    :not(.homepage) body{
        padding-top: 0px;
    }
    .alert{
        top: 11%;
    }
     /*dy changes start*/
     .image-title{
        top: 20%;
        transform: translate(0%, 0%);
        left: 21%;
     }
     .text-right .doc-btn i{
         top:8px;
     }
     .font-title {
        font-size: 25px;
    }
        a.btn.btn-sub.blue-btn.d-flex.align-items-center.position-relative.font-semi-bold.text-white.profile-submit.mr-3, .text-right a.btn.btn-sub.blue-btn{
        margin:0px 0px 20px 0px;
        }
        .text-right .doc-btn {
            /* padding: 13px 17px 13px 64px !important; */
            padding: 16px 25px 16px 60px !important;
            width: fit-content;
            width: -moz-fit-content;
        }
        .timing-count, p.text-grey.font-small.timing-count {
            text-align: left !important;
        }
        .alert{
            width:90% !important;
        }
        .slider-band .owl-carousel .owl-item img{
        height:330px;
         }
        section.services-band {
        padding: 50px 0px;
        }
    /*dy changes start*/
    /* home page responsive start 21 oct */
    img.logo-m {
        width: 100px;
    }
    .custom-range-slider .right-text {
        position: absolute;
        right: 0;
    }
    .radio-section p {
        padding-bottom: 0;
    }
    .filter-header .control-label{
        line-height: initial;
    }
    .filter-inner-content {
        padding-bottom: 50px !important;
    }
    .user-content ul li{
        word-break: break-all;
    }
    .filter-section {
        margin-bottom: 30px;
    }
    .filter-section.position-relative.filter-show {
        padding-bottom: 35px;
    }
    .inner-section-content .select2-selection--single .select2-selection__rendered{
        padding-left: 22px;
        padding-right: 0;
    }
    .custom-range-slider .left-text {
        position: absolute;
        left: 11px;
        top: 8px;
    }
    .logo-m {
        width: 120px;
    }
    .homepage:not(.navbar-fixed) .fixed-top{
        background: #fff !important;
    }
    .homepage:not(.navbar-fixed) .fixed-top .logo-white{
        display: none !important;
    }
    .fixed-top ul.center-menu.non-tranparent li a, .fixed-top .navbar-nav li a {
        color: #000;
    }
    .fixed-top .profile-dropdown h6{
        color: #265ed7 !important;
    }
    .fixed-top ul.navbar-nav.notification .dropdown-toggle::after{
        color: #cb2026 !important;
    }
    .navbar-expand-lg.fixed-bottom .navbar-collapse, .navbar-expand-lg.fixed-top .navbar-collapse{
        max-height: 100%;
    }
    .homepage:not(.navbar-fixed) .fixed-top img.logo-m{
        display: block;
    }
    .inner-section-content .form-group{
        width: 100%;
        margin:0px !important;
        padding:0px !important
    }
    .search-page .inner-section-content .form-group{
        margin: 10px 0px !important;
    }
    .f-right-content .btn-right{
        position: unset !important;
    }
    .entry-content h6{
        width: 250px;
    }
    h3{
        font-size: 18px;
    }
    .inner-section-content{
        border-radius: 17px;
    }
    .slider-content h2{
        font-size: 20px;
        padding: 0 !important;
        margin-top: 10px;
        margin-bottom: 3px;
    }
    .slider-content .doc-btn{
        position: relative !important;
    }
    .slider-content{
        bottom:0px;
        left: 54%;
        z-index: 99;
        width: 310px;
        margin: 0;
        top: 54%;
    }
    .home-slider .owl-carousel .owl-nav button.owl-next, .owl-carousel .owl-nav button.owl-prev, .owl-carousel button.owl-dot{
        padding-left: 0px !important;
    }
    .home-slider .owl-dots{
        /* top: 35%;
        left: 12px;
         */
        display: none;
    }
    .home-slider .owl-image{
        height: 300px;
    }
    .section-heading h2 {
        font-size: 25px;
    }
    .mobile-spacer{
        margin-bottom:126px;
    }
    .timing-content p {
        text-align: left !important;
    }
    .text-right .timing-count {
    text-align: right !important;
   }
    /* search page css */
    .block-content, .filter-band{
        z-index: 1;
    }
    .filter-inner-content{
        z-index: 0;
    }
    .filter-inner-content{
        left: 0;
        width: 100%;
        top: 0;
        right:auto;
    }
    .filter-btn .doc-btn{
        top: -13px;
        right: 0;
        left: 17px;
    }
    .marun-btn-text{
        right: 0;
        top: 54px;
        left: 25px;
    }
    .radio-section {
        padding-top: 30px;
    }
    .filter-section .pull-right{
        float: none;
        right: auto;
    }
    .search-block-content .right-content.text-right p, .search-footer-block .right-content{
            text-align: left !important;
    }
    .search-footer-block .right-content .doc-btn {
        top: 0;
    }
    .asRange .asRange-pointer .asRange-tip{
        width: 50px;
        height: 30px;
        font-size: 12px !important;
        margin-left: -20px;
    }
    .example.range-slider {
        padding: 0px 26px !important;
    }
    
    
    /* home page responsive start 21 oct */


    /* all buttons */
    .doc-btn{
        /* padding: 13px 64px 13px 17px !important; */
        padding: 16px 60px 16px 25px !important;
        width: fit-content;
        width: -moz-fit-content;
    }
    .doc-btn.reset-btn {
        /* padding: 11px 73px 13px 17px !important; */
        padding: 16px 60px 16px 25px !important;
        width: fit-content;
        width: -moz-fit-content;
    }
    .text-right .doc-btn.reset-btn {
        padding: 12px 12px 14px 47px !important;
    }
    .modal.show .modal-dialog {
        width: auto;
    }
    #cropperModal .modal-dialog{
        width: auto;
    }
    .appoint-form .select2-selection--single .select2-selection__arrow:after {
        right: 22px;
    }
    .nitification-count{
        right: -2px;
        top: -5px;
    }
    .doc-btn i{
        height: 30px;
        width: 30px;
        top: 8px !important;
        right: 5px;
    }
    
    .blue-btn i, .btn.blue-btn i{
        top: 8px;
    }
    ul.center-menu li a:before{
        bottom: -17px;
    }
    .navbar .float-right {
        float: none!important;
    }
    /* home page also */
    h3{
        font-size:18px !important;
    }
     /* home page also */
    .section-spacer {
        padding: 50px 0px;
    }
    .btn-right.text-right {
        text-align: left !important;
    }
    #editAvailabilityModal .modal-dialog{
        width: auto;
    }
    .profile-block{
        margin-right: 0;
    }
    .breadcrumbs .profile-header-block.text-right, .account-seeting .lang_btn{
        text-align: left !important;
    }
    .faq-card h6.card-title a{
        font-size: 15px;
    }
    .faq-card .card-group-control .card-title>a.collapsed:before, .faq-card .card-group-control-right .card-title>a:before{
        font-size: 13px;
    }
     .footer, .appoint-form .form-select .select2-container--default .select2-selection--single .select2-selection__rendered {
        text-align: center;
    }
    .profile-dropdown.d-flex.justify-content-end {
        -ms-flex-pack: end!important;
        justify-content: unset!important;
        align-items: center;
        padding: 30px 0px;
    }
    .navbar-nav .dropdown-menu{
        position: absolute;
        top: 35px;
    }
    ul.navbar-nav.notification .dropdown-menu, ul.navbar-nav.notification .dropdown-menu{
        left: 0px !important;
        top: 27px;
    }
    .profile-dropdown .dropdown-menu{
        left: 0px;
    }

    .main-menu .float-right, .form-group.input-full input{
        width: 100%;
    }
    ul.navbar-nav.notification h3, ul.sub-menu.notification h3{
        padding: 10px 18px;
    }
    ul.navbar-nav.notification .dropdown-item strong, ul.sub-menu.notification strong, .table-action i:before{
        font-size: 15px;
    }
    .social-media{
        text-align:center;
    }
    .main-menuright {
        padding: 9px;
    }
    .footer ul.footer-menu li{
        float: none !important;
    }
    .social-media ul li a{
        padding: 0px 5px;
    }
    .footer ul.footer-menu li:after{
        display: none;
    }
    .text-right ul.footer-menu {
        float: none;
    }
    .text-right .social-media {
        display: flex;
        float: none;
    }
    ul.footer-menu, .copyright p {
        margin: 0 auto;
        text-align: center;
    }   
    .footer ul.footer-menu li a{
        line-height: 30px;
    }
    .inline-div {
        display: block !important;
        padding-top: 15px;
        text-align: center;
    }
    ul.copyright-menu {
        display: inline-block;
    }
    ul.copyright-menu li{
        float: none;
        padding-bottom: 0px;
    }
    ul.copyright-menu li:after{
        display: none;
    }
    .copyright p{
        padding-bottom: 15px;
        line-height: 20px;  
        padding-right: 0;
        padding: 0px !important;
    }
    .form-group.input-w, input[type="text"], input[type="tel"], input[type="password"], input[type="date"], input[type="email"], input[type="number"], textarea {
    	width: 100%;
    }
    .other-info{
        padding:0px;
    }
    .no-padding {
        padding-left: 0px;
        padding-right: 0px;
    }
    .file-upload .file-select{
        height: auto;
        top: 0;
    }
    .form-group{
        margin: 10px 0px;
    }
    .form-title h3{
        font-size: 25px;
    }
    .contant {
        padding: 20px 0px;
    }

    /*=================== patient profile css ===================*/
    .menu-band{
        overflow: auto;
    }
    .profile-body table.table.table-bordered {
        width: 100%;
    }
    .doc_label_after:after{
        display: none;
    }
    .profile-detail ul{
        columns: unset;
    }
    .navbar-expand-lg .navbar-nav>.nav-item>.dropdown-menu{
        width: 100%;
        top:0px;
    }
    .navbar-expand-lg .navbar-nav>.nav-item>.dropdown-menu.notification_dropdown_items{
        width: 100%;
        top:6px;
    }
    /*=================== patient profile css ===================*/

    /*================================== doctor notes design ==================================*/
    .number {
        padding: 0;
    }
    .pagination a, .pagination a i:before{
        margin: 8px 9px 0;
        font-size: 12px;
    }
    .pagination a.active{
        width: 20px;
        height: 20px;
    }
    .pagination i:before{
        right: 0;
    }
    .control-btn.btn-grey, .control-btn.btn-grey i:before{
        left: 0;
        top: -5px;
    }
    .breadcrumbs .profile-detail{
        padding-left: 10px;
    }
    .text-right .text-lg-right {
        text-align: right!important;
    }
    .text-right .pagination i:before {
        right: -4px;
    }
    
    /*================================== doctor notes design ==================================*/
    p, h1, h2, h3, h5, h6{
        word-break: inherit;
    }
    /*================================== prescription design ==================================*/ 
    .col-md-6.heading-column, .col-md-6.decription-column, .suggestion-band .col-md-6.heading-column, .suggestion-band .col-md-6.decription-column {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .print-band{
        padding: 30px 0px;
    }
    .print-band img{
        width: 50%;
    }
    /*================================== prescription design ==================================*/ 
     
    /*================================== band-mesorny design ==================================*/ 
    .content-w {
        /* margin-right: 5px; */
        width: 100%;
    }
    .d-l, .d-r{
        padding:0px;
    }
    /*================================== band-mesorny design ==================================*/ 
    /*avani*/
    .custom-control.custom-checkbox.custom-control-inline {
        margin-left: 0px;
        width: 100%;
        margin-bottom: 15px;
    }
    .gen-info-after:after, .timing-info span:after {
        content: none !important;
    }
    .appoint-after:after {
        content: none !important;
    }
    .border-div{
        border-right: none !important;
    }
    .ft-w{
        font-weight: 500;
        margin-top: 14px;
    }
    .border-div .ft-w{
        padding-left: 0px !important;
    }
    .text-right.social_link {
        text-align: left !important;
    }
    .profile-header-block {
        width: 100%;
        float: none;
    }
    .info-history .info-history-btn.active {
        background-color: #265ed7;
        border: 2px solid #265ed7 !important;
        color: #fff;
    }
    .btn-list .info-history .info-history-btn {
        border: 2px solid #808080;
        border-radius: 30px;
        padding: 10px 8px;
        font-size: 15px;
        font-weight: 600;
        position: relative;
        top: 0px;
        display: block;
        margin-left: 0px !important;
        margin-top: 12px;
        width: 60%;
    }
    
    .custom-control.custom-checkbox.custom-control-inline {
        width: 100%;
        margin: 0;
        margin-bottom: 10px;
        left: 5px;
    }
    .availability-management span.select2.select2-container.select2-container--default {
        width: 40% !important;
    }
    h2, .large-text {
        font-size: 25px;
    }
    .gallery-box img {
        height: auto !important;
    }
    .button-group-doc {
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
        -webkit-column-gap: 0;
        -moz-column-gap: 0;
        column-gap: 0;
        text-align: center;
    }
    .doc-table td, .doc-table th {
        vertical-align: top;
        padding: 1.75rem 7px;
    }
    #sync1.owl-theme .owl-next, #sync1.owl-theme .owl-prev{
        top: -10%;
    }
    #sync1.owl-theme .owl-prev{
        left: 0;
    }
    #sync1.owl-theme .owl-next{
        right: 0;
    }
    .social-icon-group.text-right {
        text-align: left !important;
    }
    .reports-height{
        height: auto;
    }
    .text-right .navbar-nav{
        float: none !important;
    }
    .text-right ul.navbar-nav.notification .dropdown-menu, ul.navbar-nav.notification .dropdown-menu{
        right: 0;
    }
    .text-right ul.navbar-nav.notification h3, ul.sub-menu.notification h3, .text-right ul.navbar-nav.notification .dropdown-item strong, ul.sub-menu.notification strong, .table-action i:before{
        text-align:right;
    }
    .account-seeting .licence{
        width: -moz-fit-content;
        width: fit-content;
    }
    .bread-crumbs-careear h2, .front_banner_text h2{
        font-size: 26px;
    }
    .bread-crumbs-careear, .rules_regulations_banner {
        height: 120px;
    }
    .account-seeting button.btn.btn-danger.remove{
        top: -7px;
    }
    .profile-header-block .float-right{
        float: none !important;
    }
    .videoPoster:before{
        width: 50px;
        height: 50px;
        top: 59%;
        left: 55%;
    }
    .circle-icon, .icon-sec p {
        margin: 0 auto;
        text-align: center;
    }
    .privacy-policy-content.section-spacer, .contact_us_content.section-spacer{
        padding: 0px;
    }
    .terms_condition_content.section-spacer{
        padding: 25px 0px;
    }
    .careear-form .form-control, .front_contact .form-control, .doctor_profile_form input.form-control {
        padding: 0rem 0rem;
    }
    .about-page-content .section-spacer{
        padding: 20px 0px 15px 0px;
    }
    .careear-form .doc-btn i{
        top: 10px !important;
    }
    .careear-form .doc-btn.reset-btn i{
        top: 5px !important;
    }
    /* .about-content .doc-btn i{
        top: 9px;
        right: 8px;
    } */
    .text-right .card-group-control-right .card-title>a{
        padding-right: 1.7rem !important;
    }
}
@media screen and (max-width: 320px){
	/*avani*/
  	.history-btn.info-history-btn.active.ml-2 {
        padding: 8px 16px;
        position: relative;
        top: 40px;
        left: -11px;
    }
    .btn-list .info-history .info-history-btn {
       width: 70%;
    }
    .appoint-history .main-history hr {
        margin-top: 74px;
    }
    .full-history .padding-left-class {
        padding-left: 10px;
    }
    a.btn-i, a.btn-i i:before{
        font-size: 13px !important;
    }
}
@media screen and (max-width: 575px){
    ul.nav.nav-tabs.doctor-profile-tab li {
        flex: 100%;
        max-width: 100%;
    }
    .bg-time{
        padding: 6px 10px;
        font-size:10px;
    }
    ul.nav.nav-tabs.doctor-profile-tab{
        flex-wrap: wrap;
    }
    .apppointment-table .appoint-table-body{
        width: 104%;
    }
    button.owl-dot.active{
        padding-left: 0px !important;
    }
    .appoint-history .owl-carousel.owl-theme.image-slider .owl-nav .owl-prev {
        position: absolute;
        left: 10%;
        bottom: 0%;
    }
    .appoint-history .owl-carousel.owl-theme.image-slider .owl-nav .owl-next {
        position: absolute;
        right: 16%;
        bottom: 0%;
    }
    .appoint-time span.select2.select2-container.select2-container--default{
        width: 38% !important;
    }
    .js-select.appoint-month.css-2b097c-container
    {
        width: 38% !important;
    }
}
@media screen and (max-width: 540px){
    .appoint-time span.select2.select2-container.select2-container--default{
        width: 58% !important;
    }
    .js-select.appoint-month.css-2b097c-container{
        width: 58% !important;
    }
}
@media screen and (max-width: 320px){
	/*avani*/
  	.history-btn.info-history-btn.active.ml-2 {
        padding: 8px 16px;
        position: relative;
        top: 40px;
        left: -11px;
    }
    .btn-list .info-history .info-history-btn {
       width: 70%;
       
    }
    .appoint-history .main-history hr {
        margin-top: 74px;
    }
    .full-history .padding-left-class {
        padding-left: 10px;
    }
    .appoint-time span.select2.select2-container.select2-container--default{
        width: 70% !important;
    }
    .js-select.appoint-month.css-2b097c-container{
        width: 70% !important;
    }
    /* home page mobile responsive */

}

/********************************* ipad portrait and landscape #ip ********************************/
@media only screen and (min-width : 768px) and (max-width : 1000px){
     /*dy changes start*/
     .homepage-section .homeInnerSection .js-select .css-1wa3eu0-placeholder, .homepage-section .homeInnerSection .js-select .selectCustomClass__placeholder {
        text-align: left;
        padding: 0px !important;
    }
    .homeInnerSection .css-b8ldur-Input, .homeInnerSection .selectCustomClass__input {
        margin-left: -6px;
    }
    .homeInnerSection .inner-section-content label.control-label i:before {
        font-size: 25px !important;
    }
    .homeInnerSection .css-b8ldur-Input{
        margin: 4px;
    }
    .homeInnerSection .js-select .css-1wa3eu0-placeholder, .homeInnerSection .js-select .selectCustomClass__placeholder{
        font-size: 16px;
    }
     img.loading-image {
        width: 50%;
    }
     .search-block-content ul {
        float: right;
    }
    .search-block-content ul{
        margin: 0px !important;
    }
     /* margin: 0px !important; */
     .custom-datepicker:before{
        top: 0;
    }
    .licence{
        padding:6px;
    }
    .w-right-content .block-content {
        max-width: 100%;
    }
        a.btn.btn-sub.blue-btn.d-flex.align-items-center.position-relative.font-semi-bold.text-white.profile-submit.mr-3, .text-right a.btn.btn-sub.blue-btn{
        margin:0px 0px 20px 0px;
        }
        .text-right .doc-btn i{
            top:8px;
        }
        .image-title{
            top: 15%;
         }
         .font-title {
            font-size: 25px;
        }
        button.navbar-toggler.collapsed.jb_front_nav_close {
            right: 34px;
        }
          .timing-count, p.text-grey.font-small.timing-count {
            text-align: left !important;
        }
         .homeInnerSection .js-select .css-1wa3eu0-placeholder,
         .homeInnerSection .js-select .selectCustomClass__placeholder
         {
            left:12%;
        }
        body.text-right .timing-count, p.text-grey.font-small.timing-count {
            text-align: right !important;
        }
         .home-slider .owl-item img {
            height: 450px;
        }
        section.services-band.setion-spaccer {
            padding-top: 50px;
        }   
        /*dy changes start*/
        img{
            width:100%;;
        }
        img.logo-m {
            width: 100px;
        }
        /* home page css 19 oct*/
        .section-spacer {
            padding: 30px 0px;
        }
        .marun-btn-text {
            right: 12%;
            top: 30%;
        }
        .text-right .marun-btn-text {
            left: 12%;
            top: 30%;
            right: auto;
        }
        .welcome-band img.img-fluid{
            width: 50%;
            text-align: center;
            margin: 0 auto;
            display: flex;
        }
        .blog-band .btn-right, .f-right-content .btn-right{
            position: static !important;
            display: block;
            padding-top: 20px;
        }
        .homepage:not(.navbar-fixed) .fixed-top{
            background: #fff !important;
        }
        .homepage:not(.navbar-fixed) .fixed-top .logo-white{
            display: none !important;
        }
        .fixed-top ul.center-menu.non-tranparent li a, .fixed-top .navbar-nav li a {
            color: #000;
        }
        .fixed-top .profile-dropdown h6{
            color: #265ed7 !important;
        }
        .fixed-top ul.navbar-nav.notification .dropdown-toggle::after{
            color: #cb2026 !important;
        }
        .navbar-expand-lg.fixed-bottom .navbar-collapse, .navbar-expand-lg.fixed-top .navbar-collapse{
            max-height: 100%;
        }
        .homepage:not(.navbar-fixed) .fixed-top img.logo-m{
            display: block;
        }
        .blog-band .blog-container {
            max-width: 500px !important;
            text-align: center;
            margin: 0 auto;
        }
        .mobile-spacer {
            margin-bottom: 126px;
        }
        .services-band .col-md-4{
            -ms-flex: 100%;
            flex: 100%;
            max-width: 100%;
        }
        .section-heading .font-title{
            font-size: 25px;
        }
        .entry-content h6{
            width: 70%;
            text-align: left;
        }
        .timing-content p {
            text-align: left;
        }
        .home-slider .owl-image{
            height: 522px;
        }
        .inner-section-content .form-group{
            width: 100%;
            padding: 0px;
            margin:15px 0px !important;
        }
        .inner-section-content{
            border-radius: 17px;
        }
        .slider-content h2{
            font-size: 22px;
        }
        .slider-content .doc-btn{
            position: relative !important;
        }
        .slider-content{
            top: 35%;
            width: 50%;
        }
        .home-slider .owl-carousel .owl-nav button.owl-next, .owl-carousel .owl-nav button.owl-prev, .owl-carousel button.owl-dot{
            padding-left: 0px !important;
        }
        .inner-section-content{
                margin: 0px 0px;
        }
        /* search page */
        .filter-inner-content{
            width:100%;
            left:0;
        }
        .search-block-content .right-content.text-right p, .search-footer-block .right-content {
            text-align: left !important;
        }
        .search-footer-block .right-content .doc-btn {
            top: 0;
        }
        /* home page css 19 oct*/
        .nitification-count{
            right: -2px;
            top: -5px;
        }
        .navbar .float-right {
            float: none!important;
        }
        .reports-height{
            height: auto;
        }
        .doc_label_after:after{
        display: block;
        }
        .notes-heading .col-md-6 {
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
        }
        /* home page css it will be effect all md class */
        /* .text-md-right {
            text-align: left !important;
        } */
        /* home page css it will be effect all md class */
        ul.center-menu li a:before{
            bottom: -17px;
        }
        .navbar-light .dropdown-toggle, .number{
            margin: 0;
            padding: 0;
        }
        ul.navbar-nav.notification .dropdown-menu, ul.navbar-nav.notification .dropdown-menu{
            left: 0 !important;
            position: absolute;;
        }
        .navbar-nav .dropdown-menu{
            position: absolute;
            top: 43px;
        }
        .profile-dropdown .dropdown-menu{
            right: auto;
            top: 18px;
        }
        div#notification_dropdown {
            margin: 0px;
        }
        .main-menuright {
            padding: 0px 10px;
            display: block !important;
        }
        ul.navbar-nav.language.m-3, ul.navbar-nav.language.m-3 li.nav-item {
            margin-top: 0px !important;
            margin-left: 0px !important;
            margin-right: 0px !important;
        }
        .profile-dropdown.justify-content-end {
        -ms-flex-pack: end!important;
        justify-content: end !important;
        padding: 20px 0px !important;
        }
        .footer ul.footer-menu li{
            padding: 0px 8px;
        }
        .footer ul.footer-menu li a {
            font-size: 14px;
        }
        .footer ul.footer-menu li:after{
            height: 13px;
        }
        .form-group.input-w, input[type="text"], input[type="tel"], input[type="password"], input[type="date"], input[type="email"], input[type="number"], textarea, .form-group.input-full input {
            width: 100%;
        }
        .other-info{
            padding:0px;
        }
        .no-padding {
            padding-left: 0px;
            padding-right: 0px;
        }
        .file-upload .file-select{
            height: 50px;
            top: 10px;
        }
        
        .form-group{
            margin: 10px 0px !important;
        }
        .form-title h3{
            font-size: 25px;
        }
        .contant {
            padding: 20px 0px;
        }
        .mainBlog .float-right {
            float: right !important;
        }
        /*=================== patient profile css ===================*/
        /*=================== patient profile css ===================*/
        /*=================== doctor's notes css ===================*/
        .breadcrumbs .profile-detail{
            padding-left: 10px;
        }
        .notes-heading a span {
            color: #000;
        }
        /*=================== doctor's notes css ===================*/
        .col-md-6.heading-column, .col-md-6.decription-column, .suggestion-band .col-md-6.heading-column, .suggestion-band .col-md-6.decription-column {
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
        }
        .profile-img{
            width: 90px;
            height: 90px;
        }
        /* appointment-history */
        .appoint-history-profile {
            height: 105px;
            width: 105px;
        }
        .appoint-profile-picture.rounded-circle {
            height: 93px;
            width: 93px;
            top: 3px;
            left: 3px;
        }
    
        .gallery-box img {
            height: 130px !important;
        }
        .gallery-box img {
            height: 100px !important;
        }  
        .button-group-doc {
            -webkit-column-count: 4;
            -moz-column-count: 4;
            column-count: 4;
            -webkit-column-gap: 0;
            -moz-column-gap: 0;
            column-gap: 0;
        }
        .col-md-8.appoint-table-data, .col-md-4.availability.mt-lg-0.mt-5 {
            max-width: 100%;
            flex: 100%;
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
        }
        .appoint-history .appoint-list a {
            font-size: 12px;
        }
        .infobox .doc_label_after:after{
            display: none;
        }
        .payment-history .float-right {
            float: none !important;
        }
        .account-seeting .lang_btn{
            text-align: left;
        }
        .bread-crumbs-careear, .rules_regulations_banner{
            height: 160px;
        }
        .bread-crumbs-careear h2, .front_banner_text h2 {
            font-size: 35px;
        }
        .breadcrumbd-content{
            top: 54%;
        }
}

@media (min-width:992px){
    .main-menuright {
        display: flex !important;
    }
}
@media only screen and (min-width : 1001px) and (max-width : 1024px){
    img.loading-image {
        width: 50%;
    }
    img{
        width:100%;
    }
    .licence{
        padding:6px;
    }
    .col-md-10.col-lg-10.careers-form-content {
        flex: 100%;
        max-width: 100%;
    }
    .licence{
        padding:7px;
    }
    ul.center-menu.non-tranparent li a{
        padding: 30px 11px !important;
    }
     /* home page css 19 oct*/
         .home-slider .owl-item img {
        height: 520px;
    }
     .timing-count, p.text-grey.font-small.timing-count {
        text-align: left!important;
        }
    body.text-right .timing-count, p.text-grey.font-small.timing-count {
        text-align: right!important;
    }
     .blog-inner-content .entry-content .col-lg-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .timing-content p{
        text-align: left !important;
    }
     .marun-btn-text{
        right: 9%;
        top: 36%;
     }
     .text-right .marun-btn-text{
        left: 9%;
        top: 36%;
        right: auto;
     }
     .inner-section-content .form-group {
        width: 225px;
        padding: 0;
    }
    .blue-btn.drop-btn{
            right: 40px;
    }
    .doc-header .container .btn, .doc-header .container .btn:hover{
        padding: 14px 55px 14px 26px;
    }
    .home-slider .owl-dots{
        left: 0;
    }
    .section-heading .font-title{
        font-size: 29px;
    }
    .slider-content{
        max-width: 950px;
    }
    .entry-content h6{
        width: 250px;
    }
    .services-band .font-medium {
        font-size: 15px;
    }
    .filter-band .inner-section-content .doc-btn {
        padding: 0px !important;
        white-space: nowrap;
    }
    /* home page css 19 oct*/


    .gallery-box img {
        height: 120px !important;
    }
    ul.center-menu li a:before{
        bottom: -17px;
    }
    .navbar-light .dropdown-toggle, .number{
        margin: 0;
        padding: 0;
    }
    ul.navbar-nav.notification .dropdown-menu, ul.navbar-nav.notification .dropdown-menu{
        /* left: -100px !important; */
        position: absolute;
    }
    .navbar-nav .dropdown-menu{
        position: absolute;
        top: 25px;
    }
    .profile-dropdown .dropdown-menu{
        min-width: 100%;
        right: 0;
        left: auto;
    }
    .profile-dropdown {
        padding: 0px 20px;
    }
    .footer ul.footer-menu li{
        padding: 0px 16px;
    }
    .footer ul.footer-menu li:after{
        height: 13px;
    }
    .form-group.input-w, input[type="text"], input[type="tel"], input[type="password"], input[type="date"], input[type="email"], input[type="number"], textarea, .form-group.input-full input {
        width: 100%;
        }
        .other-info{
            padding:0px;
        }
        .no-padding {
            padding-left: 0px;
            padding-right: 0px;
        }
        .file-upload .file-select{
            height: 50px;
            top: 10px;
        }
        .patient_profile_form .file-upload .file-select{
            height: 57px;
            top: 0px;
        }
        .patient_profile_form .file-upload .file-select .file-select-name{margin-top: 14px;}
        .form-group{
            margin: 10px 0px;
        }
        .patient_profileform-section .repeateinput button.btn.btn-danger.remove{
            top: 20%;
        }
        .file-upload .file-select .file-select-button{
            padding: 17px 25px 9px 25px;
        }
        .form-title h3{
            font-size: 25px;
        }
        .contant {
            padding: 20px 0px;
        }
        .patient_profile_form .file-upload .file-select .file-select-button{
            padding: 9px 25px 9px 25px;
            height: max-content;
            margin-top: 12px;
        }
     /*=================== doctor's notes css ===================*/
    .breadcrumbs .profile-detail{
        padding-left: 10px;
    }
    .notes-heading a span {
        color: #000;
    }
     /*=================== doctor's notes css ===================*/
     .col-md-6.heading-column, .col-md-6.decription-column, .suggestion-band .col-md-6.heading-column, .suggestion-band .col-md-6.decription-column {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .infobox .doc_label_after:after{
        display: none;
    }
    .profile-img{
        width: 90px;
        height: 90px;
    }
    .appoint-history-profile {
        height: 115px;
        width: 115px;
    }
    .appoint-profile-picture.rounded-circle {
        height: 103px;
        width: 103px;
    }
    ul.center-menu.non-tranparent li a{
        font-size: 15px;
    }
    .appoint-history .appoint-list{
        margin-top: 15px;
    }
    .doc_label_after:after {
        display: block;
    }
    .bread-crumbs-careear, .rules_regulations_banner{
        height: 220px;
    }
    .bread-crumbs-careear h2, .front_banner_text h2 {
        font-size: 50px;
    }
    .front_contact_dtl_wrp h3, .front_contact_form_wrp h3 {
        font-size: 20px;
    }
    .account-seeting .form-heading p{
        font-size:14px;
    }
    .my-patient-tab .nav-pills .nav-link{
        width: 100%;
    }
    .my-patient-tab ul li {
        margin-right: 10px;
    }
    /* search page css */
    .filter-inner-content{
        width: 920px;
        left: 10px;
        top: -13px;
    }
    .radio-section.d-block.d-lg-flex {
        display: block !important;
    }
    .form-sec-one.pr-5 {
        padding: 0 !important;
    }
    .blog-inner-content .entry-content{
        padding: 26px 13px 24px;
    }
   /* search page css */
   .slider-content .form-group{margin-top: 0;}
   .doctor_profile_form .consultation-type-msg .custom-checkbox {width: auto;}
   .text-right .filter-inner-content{right: 10px; left: auto;}
}
@media only screen and (min-width : 1025px) and (max-width : 1199px){
    .licence.select-files .text-center {
        padding-left: 0px;
        text-align: center!important;
    }
    img.loading-image {
        width: 50%;
    }
    .profile-dropdown {
        padding: 0px 7px;
    }
    .history-box i:before{
        font-size: 20px;
    }
    /* home page css */
    
    .slider-content{
        width: 900px;
    }
    .services-band .font-medium {
        font-size: 15px;
    }
    .entry-content h6{
        width: 250px;
    }
    .inner-section-content .form-group {
        width: 215px;
    }
    /* search page css */
    .filter-inner-content{
        width: 920px;
        left:0;
    }
    .radio-section.d-block.d-lg-flex {
        display: block !important;
    }
    .form-sec-one.pr-5 {
        padding: 0 !important;
    }
    .blog-inner-content .entry-content{
        padding: 26px 13px 24px;
    }
   /* search page css */


}
@media only screen and (min-width : 1200px) and (max-width : 1299px){
    .home-slider .owl-item img {
    height: 625px;
    }
    .history-box i:before{
        font-size: 18px;
    }
    .payment-history h6{
        font-size: 15px;
    }
    .bread-crumbs-careear, .rules_regulations_banner {
        height: 265px;
    }
    .inner-section-content .form-group {
        width: 270px;
    }
    /* search page css */
    .home-slider .owl-image{
        height: 625px;
    }
    .slider-content{
        max-width: 1070px;
    }
    .search-page .inner-section-content .doc-btn{
        right: -47px;
        padding: 0px;
    }
    .text-right .search-page .inner-section-content .doc-btn{
        right: 4%;
        padding: 0px;
    }
    .blog-inner-content .entry-content{
        padding: 26px 13px 24px;
    }
    .filter-inner-content{
        width: 1115px;
        top: -47px;
        left: 2px;
    }
    .marun-btn-text {
        right: 8%;
        top: 61%;
    }
    .text-right .marun-btn-text {
        left: 8%;
        top: 61%;
        right: auto;
    }
    .form-sec-one.pr-5 {
        padding-right: 20px !important;
    }
    .entry-content h6{
        width: 275px;
    }
    
}
/* updated css */
@media only screen and (min-width : 1300px) and (max-width : 1395px){
    
    .filter-inner-content{
        top: -28px;
        width: 1056px;
        left: 13px;
    }
    .text-right .filter-inner-content{right: 9px; left: auto;}
}
/* updated css */

@media screen and (min-width: 1300px) and (max-width: 1700px){
    .container{
        padding-left: 30px !important;
        padding-right: 30px !important;
    }
    .resend-link-title{
        font-size: 43px !important;
    }
    .gallery-box img {
        height: 165px !important;
    }
    .modal .doc-btn{
        /* padding: 15px 74px 15px 17px; */
        padding: 16px 60px 16px 25px;
        width: fit-content;
        width: -moz-fit-content;
    }
    .text-right .modal .doc-btn{
        padding: 16px 25px 16px 60px;
        width: fit-content;
        width: -moz-fit-content;
    }
    .bread-crumbs-careear, .rules_regulations_banner{
        height: 260px;
    }
    /* home page css */
    .entry-content h6{
        width: 275px;
    }
    .blog-inner-content .entry-content{
        padding: 26px 13px 24px;
    }
    .slider-content{
        max-width: 1100px;
    }
    .inner-section-content .form-group {
        width: 285px;
    }
    .font-title{
        font-size: 33px;
    }
    .filter-inner-content{
        width: 98.5%;
    }
    /* .radio-section.d-block.d-lg-flex {
        display: block !important;
    } */
    .marun-btn-text {
        right: 80px;
        top: 55%;
    }
    .text-right .marun-btn-text {
        left: 80px;
        top: 55%;
        right: auto;
    }
    .filter-band .inner-section-content .doc-btn{
        right: 12px;
        padding: 0px;
        white-space: nowrap;
    }
    .boxed label, .boxed-gender label{
        padding: 12px 18px;
    }
    .form-sec-one {
        padding-right: 15px !important;
    }
}

@media only screen and (min-width: 200px) and (max-width:300px) {
    /* .about-content .doc-btn {
        padding: 12px 60px 12px 25px!important;
    } */
    .about-content .doc-btn i {
        top: 11px !important;
    }
    .about-content .doc-btn i:before {
        top: 1px;
    }
}
@media only screen and (min-width: 300px) and (max-width: 575px) {
    .careear-form .btn-group .doc-btn i, .doc-btn i{
        top: 11px !important;
    }
    .about-content .doc-btn {
        padding: 12px 60px 12px 25px!important;
    }
    .about-content .doc-btn i {
        top: 7px !important;
    }
    .about-content .doc-btn i:before {
        top: 1px;
    }
}
    /*=============== patient profile ===============*/ 
/*======== container width manage css ========*/
